import { Icon, jnjArrowTop } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { useScroll } from "@jmc/utils/hooks/useScroll";
import { mdiChevronUp } from "@mdi/js";
import classnames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

import style from "./style.module.scss";

interface PropTypes {
    BackToTopWrapper?: React.ElementType;
}

const BackToTop = (props: PropTypes): JSX.Element => {
    const { BackToTopWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }) => <>{children}</> } = props;
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const { jnjFullBranded } = useJnjBranding();

    const handleScroll = (): void => {
        const top = document.body.scrollTop || window.pageYOffset;
        if (top > 200) {
            setShow(true);
        } else {
            setShow(false);
        }
    };

    useScroll(handleScroll, 50);

    const handleClick = (): void => {
        if (show) {
            window?.scroll({ top: 0, behavior: "smooth" });
            document?.body?.scroll({ top: 0, behavior: "smooth" });
        }
    };

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                {show && (
                    <BackToTopWrapper>
                        <button
                            className={classnames(style.button, !show && style.hide)}
                            onClick={() => show && handleClick()}
                            aria-label={t("Back to top", { ns: "navigation" })}
                            title={t("Back to top", { ns: "navigation" })}
                            data-test-id={`back-to-top-btn`}
                            id="back_to_top"
                        >
                            <Icon
                                color="white"
                                icon={jnjFullBranded ? jnjArrowTop : mdiChevronUp}
                                size={jnjFullBranded ? "small" : "2.5rem"}
                                type={jnjFullBranded ? "jnj" : "mdi"}
                            />
                        </button>
                    </BackToTopWrapper>
                )}
            </div>
        </div>
    );
};

export default BackToTop;
