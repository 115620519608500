import { JWMHtml } from "@components/JWMHtml/JWMHtml";
import { JWMLink, regexAnonymizeEmail } from "@components/JWMLink/JWMLink";
import { ButtonImpression } from "@jmc/core/src/components/ButtonImpression/index";
import { FileDownloadImpression } from "@jmc/core/src/components/FileDownloadImpression/index";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { Button, ButtonSizes } from "@jmc/solid-design-system/src/components/atoms/Button/Button";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import urlHelper from "@jmc/solid-design-system/src/utils/url-helper";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import { default as fileHelper } from "@jmc/utils/utils/file-helper";
import { default as getServicePageUrl } from "@utils/getServicePageUrl";
import React from "react";

export type ServiceLink = {
    servicePage: string;
    specialty: { title: string };
};

export type JWMButtonProps = {
    disableLink?: boolean;
    buttonProps: {
        color: "primary" | "secondary" | "accent" | "white";
        variant: "contained" | "outlined" | "naked";
        flexibleWidth?: boolean;
        justifiedBetween?: boolean;
        noSpacing?: boolean;
    };
    placement?: string;
    url?: string;
    url_prefix?: string;
    anchor_id?: string;
    external?: boolean;
    text?: string;
    fileUrl?: string;
    size?: ButtonSizes;
    serviceLink?: ServiceLink;
    icon?: string;
    enableAnchorScroll?: boolean;
    fullWidth?: boolean;
    iconOnly?: boolean;
    rounded?: boolean;
    accessoryRight?: JSX.Element;
    accessoryLeft?: JSX.Element;
};

/**
 * JWM Button wrapper
 *
 * Provides some common logic.
 */
export const JWMButton = (props: JWMButtonProps): JSX.Element => {
    const {
        disableLink = false,
        buttonProps,
        placement = "none",
        url = null,
        url_prefix = "",
        external = false,
        anchor_id = "",
        text = null,
        fileUrl = "",
        size = "medium",
        serviceLink,
        icon = null,
        enableAnchorScroll = true,
        fullWidth = false,
        iconOnly = false,
        rounded = true,
        accessoryLeft,
        accessoryRight,
    } = props;

    const locale = useLocale() || "en-us";
    const { jnjFullBranded } = useJnjBranding();

    if (!buttonProps) return null;

    // Anonymize emails for tracking.
    const cleanupUrl = (): string => {
        let finalUrl = url;

        if (finalUrl?.includes("mailto:")) {
            if (url.includes("?")) {
                const ccIndex = url.indexOf("?");
                finalUrl = url.substring(0, ccIndex);
            }

            const toList: string[] = finalUrl?.split(",");

            for (let i = 0; i < toList.length; i++) {
                finalUrl = finalUrl?.replace(regexAnonymizeEmail, "email@");
            }
            return finalUrl;
        }
        if (finalUrl?.startsWith("tel:")) {
            return finalUrl;
        }
        return external ? url : urlHelper.getFullUrl(url, locale, external);
    };
    let finalUrl = url;
    let finalUrlPrefix = url_prefix;
    let finalAnchorId = anchor_id;
    if (serviceLink?.servicePage) {
        finalUrlPrefix = "";
        finalAnchorId = "";
        finalUrl = getServicePageUrl(serviceLink?.servicePage, serviceLink?.specialty?.title);
    }

    const phoneNumberUrl = finalUrl?.startsWith("tel:");
    const phoneNumberText = "phone-number";

    const button = (
        <ButtonImpression
            name={
                finalUrl?.includes("mailto:")
                    ? text?.replace(regexAnonymizeEmail, "email@")
                    : phoneNumberUrl
                    ? phoneNumberText
                    : text
            }
            category={
                `${buttonProps?.color} ${buttonProps?.variant}` as
                    | "primary"
                    | "secondary"
                    | "primary contained"
                    | "social sharing"
            }
            placement={placement}
            targeturl={phoneNumberUrl ? `callto ${phoneNumberText}` : finalUrl && cleanupUrl()}
            targettype={
                finalUrl?.includes("mailto:")
                    ? "mailto"
                    : phoneNumberUrl
                    ? "callto"
                    : external
                    ? "external"
                    : "internal"
            }
            fullWidth={fullWidth}
        >
            <Button
                {...buttonProps}
                data-test-id={`button.${text}`}
                size={size}
                tabIndex={-1}
                fullWidth={fullWidth}
                rounded={rounded}
                accessoryLeft={accessoryLeft}
                accessoryRight={accessoryRight}
            >
                {icon ? (
                    <>
                        <Icon icon={icon} size="small" color="inherit" />
                        {!iconOnly ? text : ""}
                    </>
                ) : (
                    <JWMHtml showGlossary={false}>{text}</JWMHtml>
                )}
            </Button>
        </ButtonImpression>
    );

    if (disableLink) {
        return button;
    }

    const component = (
        <JWMLink
            ariaLabel={text}
            url={finalUrl}
            external={external}
            fullWidth={fullWidth}
            anchor_id={finalAnchorId}
            url_prefix={finalUrlPrefix}
            shouldTrackLinkClick={false}
            enableAnchorScroll={enableAnchorScroll}
            buttonLink={jnjFullBranded || false}
        >
            {button}
        </JWMLink>
    );

    if (fileUrl) {
        return <FileDownloadImpression name={fileHelper.getFileName(fileUrl)}>{component}</FileDownloadImpression>;
    }

    return component;
};

export default JWMButton;
