import { Icon, jnjCloseNaked } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { BreakPoint, useMediaQuery } from "@jmc/solid-design-system/src/hooks/useMediaQuery/useMediaQuery";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import { CMSService } from "@jwm/types/CMSService";
import { mdiCloseCircleOutline } from "@mdi/js";
import classnames from "classnames";
import React, { useState } from "react";

import style from "./style.module.scss";

interface RecipeDisclaimerProps {
    serviceName: string;
    service?: CMSService;
}

export const RecipeDisclaimer = ({ serviceName, service }: RecipeDisclaimerProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMediaQuery(BreakPoint.lg);
    const recipeDetailsPage =
        serviceName === "recipes" && service?.service_enabled && service?.short_disclaimer && service?.long_disclaimer;
    const { jnjFullBranded } = useJnjBranding();

    return (
        recipeDetailsPage && (
            <div
                className={classnames(style.container, !isMobile || isOpen ? null : style.closed)}
                data-test-id="RecipeDisclaimer"
                onClick={isMobile ? () => setIsOpen(!isOpen) : null}
                role="none"
            >
                <div className={style.shortDisclaimer} data-test-id="Recipe.ShortDisclaimer">
                    <Typography size={jnjFullBranded ? "xs" : "s"}>{service?.short_disclaimer}</Typography>
                    {isMobile && (
                        <button className={style.closeIcon} data-test-id="RecipeDisclaimer.Close">
                            <Icon
                                icon={jnjFullBranded ? jnjCloseNaked : mdiCloseCircleOutline}
                                color="inherit"
                                size={jnjFullBranded ? "medium" : "large"}
                                type={jnjFullBranded ? "jnj" : "mdi"}
                            />
                        </button>
                    )}
                </div>
                <div className={style.longDisclaimer} data-test-id="Recipe.LongDisclaimer">
                    <Typography size={jnjFullBranded ? "xs" : "s"}>{service?.long_disclaimer}</Typography>
                </div>
            </div>
        )
    );
};
