import { TranslationProvider } from "@contexts/TranslationContext";
import { LocaleProvider } from "@jmc/core/src/hooks/useLocale";
import { createLocale } from "@jmc/utils/utils/createLocale";
import { Location } from "@reach/router";
import React, { Fragment } from "react";
import { Provider } from "react-redux";
import { LogoutWhenIdle } from "@components/LogoutWhenIdle/LogoutWhenIdle";
import { Monitoring } from "@components/Monitoring/Monitoring";
import yn from "yn";
import { Script } from "gatsby";
import { oneLine, stripIndent } from "common-tags";

/*
 * Wrap the root element with providers for:
 * - Location
 * - Translation: Adds a method to every wrapped component to translate based on a translation key.
 * - Redux for state management
 * - Monitoring (NewRelic)
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const rootElement = ({ element }, store) => {
    return (
        <Fragment>
            {/* OneTrust needs to be loaded first so it can auto-block cookies. (coming from scripts further down in the DOM) */}
            {!yn(process.env.GATSBY_DISABLE_COOKIEBANNER) && (
                <>
                    <Script
                        id="one-trust"
                        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                        type="text/javascript"
                        data-domain-script={
                            process.env.GATSBY_ONETRUST_DATA_DOMAIN +
                            (process.env.GATSBY_ONETRUST_DATA_DOMAIN.endsWith("-test")
                                ? ""
                                : process.env.GATSBY_ENVIRONMENT === "prod"
                                ? ""
                                : "-test")
                        }
                        data-document-language="true"
                        strategy="idle"
                    />
                    {/* OneTrust hook called whenever page is loaded or consent changes. */}
                    <Script
                        id="OptanonWrapper"
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: oneLine(`function OptanonWrapper() {
                                const event = new Event('OptanonActiveGroupsChanged');
                                window.dispatchEvent(event);
                            }`),
                        }}
                    />
                </>
            )}
            <Monitoring />
            <Location>
                {({ location }) => {
                    const allLanguages = process.env.GATSBY_LOCALES?.split(",");

                    // In SSR location is mostly empty and we use the url of the element instead
                    const path = location?.protocol ? location.pathname : element?.props?.url;
                    const locale = createLocale(path, 1, allLanguages);
                    return (
                        <LocaleProvider locale={locale}>
                            <TranslationProvider language={locale}>
                                <Provider store={store}>
                                    {element}
                                    <LogoutWhenIdle timeout={parseInt(process.env.GATSBY_LOGOUT_TIMEOUT) || 900} />
                                </Provider>
                            </TranslationProvider>
                        </LocaleProvider>
                    );
                }}
            </Location>
            {process.env.GATSBY_OPTIMIZELY_BUNDLE_ID && process.env.GATSBY_OPTIMIZELY_BUNDLE_ID !== "false" && (
                <Script
                    id="optimizely-script"
                    key="optimizely"
                    type="text/javascript"
                    strategy="post-hydrate"
                    dangerouslySetInnerHTML={{
                        __html: stripIndent(`
                            const optimizelyPerformanceCookieConsentCategory = '2';

                            const insertScript = function () {
                                if (
                                    ${process.env.GATSBY_DISABLE_COOKIEBANNER} ||
                                    !(
                                        window.OptanonActiveGroups &&
                                        window.OptanonActiveGroups.split(",").includes(
                                            optimizelyPerformanceCookieConsentCategory,
                                        )
                                    )
                                ) {
                                    return;
                                }

                                const script = document.createElement("script");
                                script.src = "${process.env.GATSBY_OPTIMIZELY_BUNDLE_ID}" !== "19062302001" // 19062302001 is the base personalization project, used for campaigns regarding personalized sections in pages in Contenstack
                                    ? "https://cdn.optimizely.com/public/19062302001/s/${process.env.GATSBY_OPTIMIZELY_BUNDLE_ID}.js"
                                    : "https://cdn.optimizely.com/js/19062302001.js";
                                document.head.appendChild(script);

                                window.removeEventListener("OptanonActiveGroupsChanged", insertScript);
                            };

                            window.addEventListener("OptanonActiveGroupsChanged", insertScript);
                            insertScript();
                        `),
                    }}
                />
            )}
            {/*
                We have noticed that every script inserted via GTM is placed before the OT script tag and can thus not be auto-blocked by OT.

                ATTENTION: Any script tag injected via GTM still needs to perform its own verification of consent within GTM
                ATTENTION: Any script injected at runtime should use the useScript hook which has the consent verification build in.
            */}
            <Script
                id="google-tagmanager"
                key="google-tagmanager"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: stripIndent(`
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,(f.nextSibling && f.nextSibling.nextSibling) || f);
                        })(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_TAG_MANAGER_ID}');`),
                }}
            />
        </Fragment>
    );
};
