import React, { useRef, useEffect, CSSProperties } from "react";
import analyticstracker from "@jmc/analyticstracker";
import { useCookieInteraction } from "@jmc/core/src/hooks/useCookieInteraction/index";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import { useEventListener } from "@jmc/utils/hooks/useEventListener";
import { useOnScreen } from "@jmc/utils/hooks/useOnScreen";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

type Props = {
    children: JSX.Element | React.ReactNode;
    name?: string;
    category?: "primary" | "secondary" | "primary contained" | "social sharing";
    placement?: string;
    targeturl?: string;
    targettype?: "external" | "internal" | "mailto" | "callto" | "print";
    fullWidth?: boolean;
};

const ButtonImpression = (props: Props): JSX.Element => {
    const { jnjFullBranded } = useJnjBranding();

    const { interacted } = useCookieInteraction();
    const { name, category, placement, targettype, targeturl, children, fullWidth = false } = props;
    const ref = useRef<HTMLDivElement>(null);

    const inViewport = useOnScreen(ref);
    useEffect(() => {
        if (inViewport && interacted) {
            setTimeout(() => {
                analyticstracker().trackImpression(EventTypes.BUTTON_IMPRESSION);
            }, 1000);
        }
    }, [inViewport, interacted]);

    useEventListener(
        "click",
        (e: Event) => {
            analyticstracker().trackInteraction(e, {
                changeEvent: EventTypes.BUTTON_CLICK,
            });
        },
        ref,
    );

    const style: CSSProperties = {
        display: fullWidth ? "block" : "inline-block",
        position: "relative",
    };
    if (jnjFullBranded && fullWidth) {
        style.width = "100%";
    }

    return (
        <div
            style={style}
            data-tracking-event={EventTypes.BUTTON_IMPRESSION}
            data-tracking-info={JSON.stringify({
                name,
                category,
                placement,
                targeturl,
                targettype,
            })}
            ref={ref}
        >
            {children}
        </div>
    );
};

export default ButtonImpression;
