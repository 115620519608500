/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Load the global stylesheet
import "./src/styles/global.css";
// Load the required analytics libraries
import "@jmc/analytics-transGTM";

import { Layout } from "@components/Layout/Layout";
import { create } from "@redux";
import { load as authValid } from "@redux/modules/authValid";
import { useUserInfo } from "@hooks/useUserInfo/useUserInfo";
import { anchorScroll } from "@jmc/core/src/utils/anchor-scroll";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { AccessLevel } from "./src/types/AccessLevel";

import { rootElement } from "./gatsby-shared";

if (process.env.GATSBY_ENVIRONMENT !== "prod") {
    // this is a debugging/testing library and not loaded on prod
    // required for: https://chrome.google.com/webstore/detail/event-based-web-analytics/geldghkdegbchcgbifnenjbddkfonpgb
    require("@jmc/analytics-transQA");
    require("@jmc/utils/utils/analytics-e2e");
}

// Create the redux store.
const store = create();

// onClientEntry is called when the Gatsby browser runtime first starts.
// We can use this to check whether the patient is logged in or not.
export const onClientEntry = () => {
    store.dispatch(authValid());
};

/**
 * Check if browser is not supported (Internet Explorer) to display unsupported page.
 */
const useragent = navigator.userAgent;
const hasUnsupportedBrowser = useragent.indexOf("MSIE ") > -1 || useragent.indexOf("Trident/") > -1;

/**
 * @usage Used by QA for the end-to-end tests
 *
 * This will set window.appStatus to true when all
 * components that are children of this component are mounted
 *
 * @remark
 *
 * This does not mean that all components are in their final state (fi. changes after api calls)
 * just that all the child elements have there componentDidMount called and are rendered.
 *
 * @param {{ children: any }} param0 the children to check if they rendered
 */
const CheckComponentsMounted = ({ children }) => {
    useEffect(() => {
        if (typeof window !== "undefined") {
            window.appStatus = true;
        }
    });

    return children;
};

export const wrapPageElement = ({ element, props }) => {
    let fullLayout = true;
    let do_not_index_page = false;

    // Check if it's 404 page or restricted access code page
    const useLayout = !(
        Object.keys(props?.pageContext).length === 0 ||
        props?.pageContext?.url === "/access-code" ||
        props?.pageContext?.page?.service_name === "search"
    );
    // get user info for authentication
    const userInfo = useUserInfo();

    // Check if patient declaration is showing
    if (props?.pageContext?.page?.site_reference?.[0]?.patient_declaration?.[0]?.declaration) {
        const store = JSON.parse(sessionStorage.getItem(`patientDeclaration`) || "null") || [];
        if (
            (!userInfo.isAuthenticated() &&
                props?.pageContext?.page?.access_control?.access_level === AccessLevel.Private) ||
            store?.indexOf(props?.pageContext?.page?.site_reference?.[0]?.patient_declaration?.[0]?.uid) < 0
        ) {
            fullLayout = false;
            do_not_index_page = true;
        }
    }

    return (
        <CheckComponentsMounted>
            {useLayout ? (
                <Layout {...props} fullLayout={fullLayout} do_not_index_page={do_not_index_page}>
                    {element}
                </Layout>
            ) : (
                <>
                    <Helmet>
                        <meta name="robots" content="noindex" />
                    </Helmet>
                    {element}
                </>
            )}
        </CheckComponentsMounted>
    );
};

export const wrapRootElement = (element) => rootElement(element, store);

// Checks if new route has anchors and scrolls to it with navigation offset.
export const onRouteUpdate = ({ location, prevLocation }) => {
    if (location?.pathname !== prevLocation?.pathname) {
        const anchor = location?.state?.anchor || location?.hash;

        if (anchor) {
            const listenerCallback = () => {
                setTimeout(() => {
                    document?.fonts?.ready?.then(() => {
                        // Calculate offset if TOC bar is present
                        const tocHeight = document?.getElementById("toc-bar")?.offsetHeight;
                        const offset = tocHeight > 12 ? tocHeight - 12 : 0;
                        anchor && anchorScroll(decodeURIComponent(anchor), offset);
                    });
                }, 750);
                window.removeEventListener("bodyLoaded", listenerCallback);
            };
            window.addEventListener("bodyLoaded", listenerCallback);
        } else {
            //added settimeout to prevent scrolltotop issue in firefox browser
            setTimeout(() => {
                if (!hasUnsupportedBrowser) document?.body?.scroll({ top: 0, left: 0, behavior: "smooth" });
            }, 50);
        }
    }
    /*
     * Added below code to load the OneTrust script to reload on every page except first load
     * (as a SPA application concept from OneTrust)
     * there is timeout set to wait for the script execution till complete page load.
     */
    setTimeout(function () {
        // Below session type used in order to detect the first load of the site.
        if (sessionStorage.firstload) {
            sessionStorage.firstload = false;

            // before reloading the script, remove the existing the OneTrust loaded DIV to avoid duplicates
            const otConsentSdk = document.getElementById("onetrust-consent-sdk");
            if (otConsentSdk) {
                otConsentSdk.remove();
            }

            // Reload the Script by calling onetrust.loadbanner() function.
            window?.OneTrust?.LoadBanner();
        } else {
            sessionStorage.firstload = true;
        }
    }, 500);
};
